.linetok table tr{
  cursor: pointer;
}

.linetok table tr td{
  text-align: center;
}

.detaillinetok table tr th{
  width: 150px;
}