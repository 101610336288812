.App {
  text-align: center;
}

.dashboard table thead tr th{
  text-align: center;
}

.dashboard table tbody tr{
  cursor: pointer;
}

.dashboard .more_btn{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #262327;
}

.dashboard .more_btn img{
  width: 16px;
  height: 16px;
}

.dashboard .card_head_title{
  font-size: 20px;
  line-height: 29px;
}

.dashboard .company_img{
  box-sizing: border-box;
  width: 120px;
  height: 120px;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
}

.dashboard .company_name{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #262327;
}

.dashboard .company_des{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #262327;  
}

.dashboard .w_160{
  width: 160px;
}
.dashboard .w_100{
  width: 100px;
}

.dashboard .w_200{
  width: 200px;
}

.dashboard table tr td{
  text-align: center;
}