.menu_group_open{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 7px;
    background: #000000;
}

.menu_group_open_self{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background: #000000;
}
.menu_group_close{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background: #262327;
}

.menu_g_item{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px;
    box-sizing: border-box;
    width: 100%;

    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #CCCCCC;
}