.mnglist table tr{
    cursor: pointer;
}

.mnglist table tr td{
    text-align: center;
}

.detailpermission .field_row{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 5px;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #262327;
    gap: 20px;
    flex-wrap: wrap;
}

.detailpermission .card_row{
    border: none;
}

.detailpermission .card_body{
    border: 1px solid #EEEEEE;
    gap: 10px;
    padding:10px;
    box-sizing: border-box;
}

.detailpermission table tr td{
    text-align: center;
}