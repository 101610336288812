.foot_section{
    position:fixed;
    left: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 100vw;
    height: 58px;

    background: #FFFFFF;
    border-top: 1px solid #D9D9D9;

    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #666666;
}