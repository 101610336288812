.crmreviwer .card{
    min-width: 1400px;
}
.crmreviwer table tr td{
    text-align: center;
}
.crmqueryprod table tr td{
    text-align: center;
}

.crmqueryprod table tr{
    cursor: pointer;
}

.detailcrmprod table tr th{
    width: 100px;
}

.detailcrmprod table tr td{
    padding-left: 10px;
}

.crmquerysvc table tr td{
    text-align: center;
}
.detailcrmprod .imgbg{
    height: 100px;
    border: 1px solid #EEEEEE;
}
.detailreviewer table tr th{
    width: 100px;
}
.detailreviewer table tr td{
    padding-left: 10px;
}

.crmreport table tr td{
    text-align: center;
}

.detailreport table tr th{
    width: 180px;
}

.detailreport table tr td{
    padding-left: 10px;
}