.menu_item_open{
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 5px 30px;
    background: #000000;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: #FFFFFF;
    width: 100%;
}

.menu_item_close{
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 5px 30px;
    background: #000000;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #FFFFFF;
    width: 100%;
}