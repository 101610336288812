.ReactModal__Content .card{
    height: 100%;
}

.ReactModal__Content .card_body{
    flex: 1;
    overflow-y: scroll;
    border: 1px solid #EEEEEE;
}

.close_modal{
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-image: url(/public/images/ico_x.png);
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
}
.modal_bottom{
    width: 100%;
    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ReactModal__Content .edukeyword table tbody tr{
    cursor: pointer;
}

.ReactModal__Content table tbody td{
    text-align: center;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #262327;
}

.ReactModal__Content .edukeyword table tbody tr.sel_item td{
    background-color: #999999;
}

.ReactModal__Content .key_button{
    cursor: pointer;
    background-color: #EEEEEE;
    padding: 5px 10px;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #262327;
}

.querypreview table tbody th{
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #262327;
}

.removedlg .card_head_title{
    font-size: 16px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
}
.removedlg .message_txt{
    width: 100%;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #262327;
    text-align: center;   
}